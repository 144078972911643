<template>
  <div id="management">
    <div class="content">
      <div class="container">
        <p class="tab-setting" v-if="updateIntellectual == true">
          แก้ไขนวัตกรรม
        </p>
        <p class="tab-setting" v-else>เพิ่มนวัตกรรม</p>
        <div class="box-manage-admin">
          <el-row :gutter="30">
            <el-col :span="24" class="set-box name-list">
              <p>ข้อมูลนวัตกรรม</p>
            </el-col>
            <el-form
              ref="form"
              :label-position="'top'"
              :model="form"
              :rules="rules"
            >
              <el-col :span="24" class="set-box">
                <el-row :gutter="15">
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item
                      label="ชื่อศูนย์บริการทางการแพทย์"
                      prop="serviceId"
                    >
                      <el-select
                        v-model="form.serviceId"
                        placeholder="กรุณาเลือกศูนย์บริการ"
                      >
                        <el-option
                          v-for="item in dataService"
                          :key="item._id"
                          :label="item.nameTH"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ชื่อนวัตกรรม" prop="projectName">
                      <el-input v-model="form.projectName">
                      </el-input> </el-form-item
                  ></el-col>
                </el-row>
              </el-col>

              <el-col
                :span="24"
                class="set-box"
                style="padding-top: 0"
                v-for="(roundInnovationName, index) in form.innovationName"
                :key="index"
              >
                <el-row :gutter="15">
                  <el-col :span="24" :md="18">
                    <el-form-item label="รายการนวัตกรรม">
                      <el-input v-model="roundInnovationName.name"> </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="6" class="box-openday">
                    <el-row :gutter="15">
                      <el-col :span="12" :sm="12" :md="12">
                        <el-form-item label="เปิดปิด">
                          <el-button
                            type="primary"
                            class="add-openday"
                            @click="addinnovationName()"
                            >เพิ่มนวัตกรรม</el-button
                          >
                        </el-form-item>
                      </el-col>
                      <el-col :span="12" :sm="12" :md="12">
                        <el-form-item label="เปิดปิด">
                          <el-button
                            type="primary"
                            class="del-openday"
                            @click="delinnovationName(index)"
                            >ลบ</el-button
                          >
                        </el-form-item></el-col
                      >
                    </el-row>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ชื่อประเภทนวัตกรรม">
                      <el-select
                        v-model="form.innovationName[index].type"
                        placeholder="กรุณาประเภทด้านนวัตกรรม"
                      >
                        <el-option
                          v-for="item in dataType"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ระดับความพร้อมของเทคโนโลยี">
                      <el-select
                        v-model="form.innovationName[index].level"
                        placeholder="ระดับความพร้อมของเทคโนโลยี"
                      >
                        <el-option
                          v-for="item in dataLevel"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ความร่วมมือ / แหล่งเงินทุนสนับสนุน">
                      <el-input v-model="form.innovationName[index].sourceFund">
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24">
                    <el-upload
                      class="upload-demo"
                      action="#"
                      :auto-upload="false"
                      :on-change="(file) => uploadImage(file, index)"
                      :on-remove="(file) => handleRemove(index)"
                      :limit="1"
                      :file-list="
                        updateIntellectual == true ? fileList[index].attach : []
                      "
                    >
                      <el-button size="small" class="btn-upload"
                        >คลิกเพื่ออัปโหลดไฟล์นวัตกรรม
                      </el-button>
                    </el-upload>
                  </el-col>
                </el-row>
              </el-col>
            </el-form>
          </el-row>
        </div>
        <div class="setbtn">
          <router-link to="/manageinnovation">
            <button class="btn btn-color-dark btn-cancle">
              <span>ยกเลิก</span>
            </button>
          </router-link>
          <button
            class="btn btn-color-dark btn-save"
            @click="updateFormInnovation()"
            v-if="updateIntellectual == true"
          >
            <span>บันทึก</span>
          </button>
          <button
            class="btn btn-color-dark btn-save"
            @click="createInnovation()"
            v-else
          >
            <span>บันทึก</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios.js";
export default {
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  mounted() {
    this.getInnovationById();
    this.getDataService();
  },
  components: {},
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      updateIntellectual: false,
      dataService: [],
      dataType: [
        { id: 1, name: "Diagnostics and Laboratory technology" },
        {
          id: 2,
          name: "Medical Devices",
        },
        {
          id: 3,
          name: "Pharmaceutical and therapeutic",
        },
        {
          id: 4,
          name: "Immunization/vaccine and biologicals",
        },
        {
          id: 5,
          name: "eHealth/mHealth/Telehealth/Medical software and Imaging",
        },
        {
          id: 6,
          name: "อื่นๆ",
        },
      ],
      dataLevel: [
        { id: 1, name: "องค์ความรู้และงานวิจัยพื้นฐาน" },
        {
          id: 2,
          name: "ต้นแบบห้องปฏิบัติการ",
        },
        {
          id: 3,
          name: "ต้นแบบภาคสนาม",
        },
        {
          id: 4,
          name: "พร้อมออกสู่ตลาด",
        },
      ],
      image: "",
      fileList: [],
      returnId: "",
      form: {
        serviceId: "",
        innovationName: [
          {
            name: "",
            action: "new",
            attach: [],
            type: "",
            level: "",
            sourceFund: "",
          },
        ],
      },
      rules: {
        projectName: {
          required: true,
          message: "กรุณากรอกชื่อทรัพย์สินทางปัญญา",
        },
        sourceFund: {
          required: true,
          message: "กรุณากรอกแหล่งเงินทุนสนับสนุน",
        },
        serviceId: [
          {
            required: true,
            message: "กรุณาเลือกศูนย์บริการ",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    addinnovationName() {
      this.form.innovationName.push({
        name: "",
        action: "new",
        attach: [],
        type: "",
        level: "",
        sourceFund: "",
      });
    },
    delinnovationName(index) {
      if (this.form.innovationName.length > 1) {
        this.form.innovationName.splice(index, 1);
      }

      //ส่วนที่ comment ไว้ไม่แน่ใจว่าใส่ไว้ทำไมเพราะลบแถวแรกไปก็น่าจะหมายละก็ใช้งานไม่ได้เพราะไม่มีการระบุตัวแหน่งของ array ถ้าไม่ได้ไว้ใช่ไรก็ลบทิ้งเลย comment ไว้ให้ก่อนเพื่อใช้
      // if (this.form.innovationName.attach.length > 1) {
      //   this.form.innovationName.attach.splice(index, 1);
      // }
      // if (this.form.innovationName.action.length > 1) {
      //   this.form.innovationName.action.splice(index, 1);
      // }
      // if (this.form.innovationName.type.length > 1) {
      //   this.form.innovationName.type.splice(index, 1);
      // }
      // if (this.form.innovationName.level.length > 1) {
      //   this.form.innovationName.level.splice(index, 1);
      // }
      // if (this.form.innovationName.sourceFund.length > 1) {
      //   this.form.innovationName.sourceFund.splice(index, 1);
      // }
    },
    uploadImage(file, index) {
      this.image = file;
      this.form.innovationName[index].attach.push(this.image.raw);
    },
    handleRemove(index) {
      this.form.innovationName[index].attach = [];
    },
    getDataService() {
      HTTP.get(`services/${0}/${0}/${"all"}/0`)
        .then((res) => {
          if (res.data.success) {
            this.dataService = res.data.obj;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getDataService error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    getInnovationById() {
      if (typeof this.routeParams.id != "undefined") {
        HTTP.get(`innovation/by/${this.routeParams.id}`)
          .then((res) => {
            if (res.data.success) {
              this.form = res.data.obj;
              console.log("id", this.form);
              this.form.innovationName = res.data.obj.innovationList;
              this.updateIntellectual = true;
              //  if (typeof this.form.innovationList != "undefined") {
              for (var i = 0; i < this.form.innovationName.length; i++) {
                var getDataFile = {
                  _id: this.form.innovationName[i]._id,
                  name: this.form.innovationName[i].name,
                  type: Number(this.form.innovationName[i].type),
                  level: Number(this.form.innovationName[i].level),
                  sourceFund: this.form.innovationName[i].sourceFund,
                  attach: [
                    {
                      name: this.form.innovationName[i].attach.file,
                    },
                  ],
                };
                this.fileList.push(getDataFile);

                if (this.fileList[i].attach[0].name == null) {
                  this.fileList[i].attach  = []
                }
              }
              console.log( this.fileList);
              this.form.innovationName = this.fileList;
            } else {
              this.alertFailError();
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("fetchService error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          });
      }
    },

    updateFormInnovation() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          this.returnId = this.routeParams.id;

          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          for (var i = 0; i < this.form.innovationName.length; i++) {
            var formData = new FormData();
            formData.append("serviceId", this.form.serviceId);
            formData.append("name", this.form.projectName);
            if (typeof this.form.innovationName[i]._id != "undefined") {
              formData.append("_id", this.form.innovationName[i]._id);
            }
            formData.append("innovationName", this.form.innovationName[i].name);
            formData.append("action", this.form.innovationName[i].action);
            formData.append("attach", this.form.innovationName[i].attach[0]);
            formData.append(
              "sourceFund",
              this.form.innovationName[i].sourceFund
            );
            formData.append("type", this.form.innovationName[i].type);
            formData.append("level", this.form.innovationName[i].level);
            HTTP.put(`innovation/${this.returnId}`, formData, config)
              .then((res) => {
                if (res.data.success) {
                  this.$router.push(`/manageinnovation`).catch(()=>{})
                } else {
                  this.alertFailError();
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("update innovation error", e);
              });
            if (i + 1 == this.form.innovationName.length) {
              this.$message({
                message: "แก้ไขข้อมูลสำเร็จ",
                type: "success",
                duration: 4000,
              });
            }
          }
        }
      });
    },
    createInnovation() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          var formData = new FormData();
          formData.append("serviceId", this.form.serviceId);
          formData.append("name", this.form.projectName);
          formData.append("sourceFund", this.form.sourceFund);
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`innovation`, formData, config)
            .then((res) => {
              if (res.data.success) {
                this.returnId = res.data.obj._id;
                for (var i = 0; i < this.form.innovationName.length; i++) {
                  var formData = new FormData();
                  formData.append("serviceId", this.form.serviceId);
                  formData.append("name", this.form.projectName);

                  formData.append(
                    "innovationName",
                    this.form.innovationName[i].name
                  );

                  formData.append(
                    "sourceFund",
                    this.form.innovationName[i].sourceFund
                  );
                  formData.append("type", this.form.innovationName[i].type);
                  formData.append("level", this.form.innovationName[i].level);
                  formData.append("action", this.form.innovationName[i].action);
                  formData.append(
                    "attach",
                    this.form.innovationName[i].attach[0]
                  );
                  HTTP.put(`innovation/${this.returnId}`, formData, config)
                    .then((res) => {
                      console.log("update" + res);
                      if (res.data.success) {
                        console.log("ads");
                      } else {
                        this.alertFailError();
                      }
                    })
                    .catch((e) => {
                      this.alertCatchError(e);
                      console.log("update innovation error", e);
                    });
                }

                this.$message({
                  message: "บันทึกข้อมูลสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manageinnovation`);
              } else {
                this.alertFailError();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("create intellectual error", e);
            });
        }
      });
    },
  },
};
</script><style scoped>
.btn-save {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  margin-left: 15px;
}
.btn-cancle {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  background: transparent;
  color: #7e828f;
  border: 1px solid #7e828f;
}
.btn-upload {
  width: 100%;
  display: block;
  padding: 11px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  background: #9e76b4;
  cursor: pointer;
}
</style>